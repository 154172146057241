<template>
    <div class="banner-wrapper">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="banner-wrapper-content">
                        <h1>Payments at your Fingertips </h1>
                        <p>At Paymi Solutions Limited, we simplify payments for individuals and businesses of all sizes, ensuring secure and efficient transactions for everyone.</p>
                        <a href="mailto:Projects@paymi.com.ng" class="btn btn-primary">CONTACT US</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="banner-wrapper-image">
                        <img src="../../assets/images/banner-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainBanner'
    }
</script>